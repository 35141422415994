<template>
	<div class="pgbody">
		<div class="phone_con" style="">
			<img class="phone_line" src="../../assets/images/sy/line.png">
			<img class="phone_p" src="../../assets/images/sy/500.png">
			<div class="assessment-box">
				<div class="assessment-intbox">
					<!-- 标题 -->
					<!-- 请选择你的学历 -->
					<div style="margin: ;" class="education-box">
						<h4 style="margin-bottom: 1rem;margin-top: 0.625rem;width:100%;text-align:left">
							<span>*</span>请选择你的学历：
						</h4>
						<div class="education-cont" style="margin-bottom: 0;">
							<div v-for="(item, index) in educationList" :key="index"
								@click="handleClickEducation(item, index)"
								:class="['cont-checked',educationCurrent == index ? 'cont-checked-Select' : '']">{{
								item.title }}</div>
						</div>
					</div>
					<!-- 请选择你的学历 -->
					<div class="ege-box">
						<h4 style="margin-bottom: 1rem;margin-top: 1.25rem;text-align:left">
							<span>*</span>请选择你的年龄：
						</h4>
						<div class="education-cont" style="margin-bottom: 0px;margin-top: 10px;">
							<div v-for="(item, index) in ageList" :key="index" @click="handleClickAge(item, index)"
								:class="[
								  'cont-checked',
								  ageCurrent == index ? 'cont-checked-Select' : ''
								]">
								{{ item.title }}
							</div>
						</div>
					</div>
					<div class="input-btn-box">
						<div class="phone-box">
							<input class="phone-cost" type="text" v-model="inputVal" placeholder="输入手机号 评估学习费用" />
						</div>
						<div class="freeAssessment" @click="handleClickFree">评估一下</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "../../assets/css/newPublic.css";
	import header_public from "../../components/header_public/header_public";
	import Swiper from "swiper/js/swiper.min.js";
	const axios = require("axios").default;
	import banner_app from '../../components/bannerApp/banner_app';
	import banner_yx from '../../components/bannerApp/banner_yx';

	import banner_gw from '../../components/bannerApp/banner_gw';
	import banner_gzh from '../../components/bannerApp/banner_gzh';
	import banner_ht from '../../components/bannerApp/banner_ht';
	import banner_ksh from '../../components/bannerApp/banner_ksh';
	import banner_xcx from '../../components/bannerApp/banner_xcx';
	import banner_zm from '../../components/bannerApp/banner_zm';
	export default {

		data() {
			return {
				imgdata1: [{
						img: require("../../assets/images/app1.jpg")
					},
					{
						img: require("../../assets/images/app2.png")
					},
					{
						img: require("../../assets/images/app3.jpg")
					},
					{
						img: require("../../assets/images/app4.png")
					}
				],
				imgdata2: [{
						img: require("../../assets/images/youxi1.png")
					},
					{
						img: require("../../assets/images/youxi2.png")
					},
					{
						img: require("../../assets/images/youxi3.png")
					},
					{
						img: require("../../assets/images/youxi4.png")
					}
				],
				imgdata3: [{
						img: require("../../assets/images/xcx1.png")
					},
					{
						img: require("../../assets/images/xcx2.png")
					},
					{
						img: require("../../assets/images/xcx3.jpg")
					},
					{
						img: require("../../assets/images/xcx4.jpg")
					}
				],
				imgdata4: [{
						img: require("../../assets/images/gzh1.jpg")
					},
					{
						img: require("../../assets/images/gzh2.jpg")
					},
					{
						img: require("../../assets/images/gzh3.png")
					},
					{
						img: require("../../assets/images/gzh4.jpg")
					}
				],
				educationList: [{
						title: "初中"
					},
					{
						title: "高中"
					},
					{
						title: "中专"
					},
					{
						title: "大专"
					},
					{
						title: "本科及以上"
					}
				],
				educationCurrent: 0,
				educationText: "初中",
				ageList: [{
						title: "18周岁以下"
					},
					{
						title: "18-25周岁"
					},
					{
						title: "25-30周岁"
					},
					{
						title: "30周岁+"
					}
				],
				ageCurrent: 0,
				ageText: "18周岁以下",
				inputVal: "",
				offerlist: [],
				swiperJob: null,
				menu: [
					"手机APP开发",
					"HTML5游戏开发",
					"桌面应用",
					"小程序开发",
					"微信公众号开发",
					"各大官网",
					"后台管理系统",
					"数据可视化"
				],
				categoryIndex: 0
			};
		},
		components: {
			header_public,
			banner_app,
			banner_yx,
			banner_gw,
			banner_gzh,
			banner_ht,
			banner_ksh,
			banner_xcx,
			banner_zm
		},
		created() {

		},
		mounted() {},
		methods: {
			kefu() {
				window.mantis.requestChat()
			},
			clickcategory(item, index) {
				this.categoryIndex = index;
			},
			handleClickEducation(item, index) {
				this.educationCurrent = index;
				this.educationText = item.title;
			},
			handleClickAge(item, index) {
				this.ageCurrent = index;
				this.ageText = item.title;
			},
			handleClickFree() { 
				if (localStorage.clickNum == 1) {
					alert('您已提交，请不要重复提交');
					return false;
				} 
				var url = encodeURIComponent(window.location.href); 
				var param = {
					age: this.ageText,
					education: this.educationText,
					source_url: url,
					telephone: this.inputVal
				}; 
				axios
					.post("/api/audition/apply", param)
					.then(res => {
						console.log(res)
						if (res.data.code == 1) {
							localStorage.clickNum = '1' 
							console.log(111)
							window.sendPage(this.inputVal, this.educationText)
						} else {
							console.log(222)
							alert(res.data.msg); 
						}
					})
					.catch(errors => {
						alert(errors.data.msg);
					}); 
			}
		}
	};
</script>

<style scoped>
	@import url("../../assets/css/calculationPage2.css");

	.pgbody {
		width: 1200px;
		height: auto;
		position: relative;
		background-color: #FFFFFF;
		margin: 0 auto;
	}

	.phone_line {
		width: 970px;
		height: auto;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.phone_p {
		position: absolute;
		top: -20px;
		right: 40px;
		z-index: 10;
		width: 382px;
	}


	.phone_con {
		width: 900px;
		height: auto;
		text-align: left;
		box-shadow: 2px 2px 2px #F5F5F5;
		border-radius: 10px;
		background-color: #f6f6f6;
		padding-bottom: 20px;
		margin-top: 50px;
	}

	/* @import url("../../assets/css/calculationPage1.css"); */

	.cont-img {
		width: 1.0625rem;
		height: 1.6875rem;
		margin-left: 20px;
	}
</style>
